import { isImmutableDefault } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { lmsApi } from '../services/lms';
import history from './history';
import {
  handleSaveSuccessMiddleware,
  resetFieldsMiddleware,
} from 'accountAddon/accountAddon.middlewares';
import modalMiddleware from 'core/modals/modals.middleware';

const extraMiddlewares = [
  // vendor:
  routerMiddleware(history), // Connected Router

  // services:
  lmsApi.middleware,

  // features:
  modalMiddleware,
  resetFieldsMiddleware,
  handleSaveSuccessMiddleware,
];

const configureMiddleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    // supresses `A non-serializable value was detected in the state`:
    serializableCheck: false,
    immutableCheck: {
      ignoredPaths: [],
      // don't check files for immutability (Redux Forms files upload):
      isImmutable: (value) => isImmutableDefault(value) || value instanceof File,
    },
  }).concat(...extraMiddlewares);

export default configureMiddleware;
