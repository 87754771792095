import { RESET_TRIGGERS } from './AccountForm';
import { lmsApi } from 'core/services/lms';
import { change } from 'redux-form';
import * as R from 'ramda';
import { addSuccessMessageField } from './accountAddon.slice';

export const resetFieldsMiddleware = (storeAPI) => (next) => (action) => {
  // FIXME: action type from hardcode to library variable
  if (action.type === 'lmsApi/executeMutation/fulfilled') {
    const fields = Object.keys(action.meta.arg.originalArgs);
    let updateBody = {};

    // reset other fields
    Object.entries(RESET_TRIGGERS).forEach((entry) => {
      const [key, data] = entry;
      const triggers = data.triggers;
      const defaultValue = data.defaultValue;

      // if changed field is trigger to reset other field - positive reset
      if (triggers.some((v) => fields.includes(v))) {
        updateBody[key] = defaultValue;
        storeAPI.dispatch(change('account', key, defaultValue));
      }
    });

    // send request only when body isn't empty
    if (Object.keys(updateBody).length) {
      storeAPI.dispatch(lmsApi.endpoints.updateAccount.initiate(updateBody));
    }
  }

  return next(action);
};

export const handleSaveSuccessMiddleware = (storeAPI) => (next) => (action) => {
  if (
    action.type === 'lmsApi/executeMutation/fulfilled' &&
    action.meta.arg.endpointName === 'updateAccount'
  ) {
    const fields = R.keys(action.meta.arg.originalArgs);
    if (fields.length === 1) {
      // FIXME: without it reset trigger show success message for all reset fields
      storeAPI.dispatch(addSuccessMessageField(fields));
    }
  }

  return next(action);
};
