import { connectRouter } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';
import { lmsApi } from '../services/lms';
import modalReducer from '../modals/modals.slice';
import questionnaireReducer from 'questionnaire/questionnaire.slice';
import accountAdddonReducer from 'accountAddon/accountAddon.slice';

const appReducer = (history) => ({
  // vendor
  form: reduxFormReducer, // Redux Forms
  router: connectRouter(history), // Connected Router

  // services
  [lmsApi.reducerPath]: lmsApi.reducer,

  // features
  modal: modalReducer,
  questionnaire: questionnaireReducer,
  accountAddon: accountAdddonReducer,
});

export default appReducer;
