import AccountAddonFallback from 'accountAddon/AccountAddonFallback';
import AccountForm, { OCCUPATION_OPTIONS } from 'accountAddon/AccountForm';
import { useGetAccountQuery, useUpdateAccountMutation } from 'core/services/lms';
import { pickFieldValues } from 'core/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { Portal } from 'react-portal';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { asyncValidate } from './AccountForm/validators';
import BasicInfo from './basicInfo';
import EducationInfo from './educationInfo';

const AccountAddon = ({ node }) => {
  const { data: fields } = useGetAccountQuery();
  const accountFormValues = useSelector(getFormValues('account'));
  const [updateField, result] = useUpdateAccountMutation();

  return (
    <Portal node={node}>
      <AccountForm
        form="account"
        initialValues={pickFieldValues(fields)}
        destroyOnUnmount={false}
        enableReinitialize={true}
        keepDirtyOnReinitialize={true}
        touchOnChange={true}
        asyncChangeFields={[
          'country',
          'occupation',
          'region',
          'city',
          'high_school',
          'last_university',
          'student_university',
          'current_university',
          'gender',
          'year_of_birth',
          'graduated_studies',
        ]}
        asyncBlurFields={[
          'custom_last_university',
          'custom_high_school',
          'custom_student_university',
          'custom_current_university',
          'phone_number',
        ]}
        asyncValidate={(...props) => asyncValidate(updateField, result, ...props)}
      >
        <BasicInfo fields={fields} />
        {Object.values(OCCUPATION_OPTIONS).includes(accountFormValues?.occupation) && (
          <EducationInfo fields={fields} />
        )}
      </AccountForm>
    </Portal>
  );
};

AccountAddon.propTypes = {
  node: PropTypes.object.isRequired,
};

export default withErrorBoundary(AccountAddon, { FallbackComponent: AccountAddonFallback });
