import App from 'App';
import { ConnectedRouter } from 'connected-react-router';
import { MOUNT_POINTS, waitForIt } from 'core/async';
import 'core/global.scss';
import store from 'core/store';
import history from 'core/store/history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

const initApp = (root) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    root,
  );
};

// App initiates after the root node is found:
waitForIt(MOUNT_POINTS.appRoot, initApp, 4);
