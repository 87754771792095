import { MOUNT_POINTS } from 'core/async';
import { withErrorBoundary } from 'react-error-boundary';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../modals.slice';
import ModalFallback from '../ModalFallBack';

const defaultContentStyle = {
  display: 'block',
  width: 525,
  height: 'auto',
  maxWidth: 'calc(100vw - 20px)',
  maxHeight: 'calc(100vh - 20px)',
  padding: 30,
  background: '#fff',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 0,
  overflow: 'auto',
  boxSizing: 'border-box'
};

const overlayStyle = {
  display: 'block',
  opacity: 1,
  background: 'rgba(64,81,97,0.8)',
  height: '100%',
  left: 0,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 100,
};

export function Modal({ children }) {
  const { content, ...modalState } = useSelector((store) => store.modal);
  const dispatch = useDispatch();

  ReactModal.setAppElement(MOUNT_POINTS.appRoot);

  const handleClose = () => dispatch(closeModal());

  return (
    <ReactModal
      // UI:
      portalClassName="customizations-modal"
      //       overlayClassName="customizations-modal-overlay"
      style={{ overlay: overlayStyle, content: defaultContentStyle }}
      className={'modal ats-modal'}
      // Content:
      contentElement={(props, children) => {
        const Content = content;
        return <div {...props}>{<Content /> || children}</div>;
      }}
      // Options:
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      closeTimeoutMS={0}
      preventScroll={true}
      // Handlers:
      onRequestClose={handleClose}
      // State overrides:
      {...modalState}
    >
      {children}
    </ReactModal>
  );
}

export default withErrorBoundary(Modal, { FallbackComponent: ModalFallback });
