import { gettext as _ } from 'core/utils';
import { useGlobalReportMutation } from 'core/services/lms';
import { useParams } from 'react-router-dom';
import styles from '../reports.module.scss';
import ReportMessage from 'components/ReportMessage';
import InsertPortal from 'core/async/InsertPortal';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import ReportsFallback from 'reports/ReportsFallback';

const GlobalReport = ({ node, insertBefore }) => {
  const [postGlobalReport, result] = useGlobalReportMutation();
  const { courseId } = useParams();
  const isStaff = window.platformSettings.user.isStaff;
  const isSuperuser = window.platformSettings.user.isSuperuser;

  if (!isStaff && !isSuperuser) {
    return null;
  }

  return (
    <InsertPortal node={node} insertBefore={insertBefore}>
      <div className={styles['report']}>
        <h3 className="hd hd-3">{_('Global Report')}</h3>
        <p>
          {_(
            'In this report, you will find information about all registered users on the platform with their data from their accounts and a questionnaire they have to fill in before starting learning.',
          )}
        </p>
        <p>
          <input
            type="button"
            value={_('All registered users on the platform')}
            onClick={() => postGlobalReport({ course_id: courseId })}
          />
        </p>
        <p>{_('Click to generate a CSV file. The report generation process can take a while.')}</p>
        {result.isSuccess && (
          <ReportMessage text={result.data.status} className={styles['success-message']} />
        )}
      </div>
      <hr />
    </InsertPortal>
  );
};

GlobalReport.propTypes = {
  node: PropTypes.object.isRequired,
  insertBefore: PropTypes.object,
};

export default withErrorBoundary(GlobalReport, { FallbackComponent: ReportsFallback });
