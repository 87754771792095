import { Col, Form } from '@edx/paragon';
import { gettext as _ } from 'core/utils';
import SelectField from '../SelectField';

const WidgetSwitch = ({
  id,
  value,
  name,
  onChange,
  onBlur,
  widget,
  placeholder,
  options,
}) => {
  switch (widget) {
    case 'number':
      return (
        <Form.Control
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type="number"
          min="0"
        />
      );
    case 'text':
      return (
        <Form.Control
          id={id}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          type="text"
        />
      );
    case 'select':
    case 'select-search':
      return (
        <SelectField
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          options={options}
          search={widget === 'select-search'}
        />
      );
    case 'radio':
      return (
        <Form.Group>
          <Form.RadioSet id={id} name={name} onChange={onChange} value={value}>
            {Object.keys(options).map((opt) => (
              <Form.Radio value={opt} key={opt}>
                {options[opt]}
              </Form.Radio>
            ))}
          </Form.RadioSet>
        </Form.Group>
      );
    case 'checkboxes':
      return (
        <Form.Group>
          <Form.CheckboxSet
            id={id}
            name={name}
            value={value}
            onChange={(e) => {
              const currentValue = e.target.value;
              let newValue;

              if (e.target.checked) {
                newValue = [...value, currentValue];
              } else {
                newValue = value.filter((item) => item !== currentValue);
              }

              onChange(newValue);
            }}
          >
            {Object.keys(options).map((opt) => (
              <Form.Checkbox value={opt} key={opt}>
                {options[opt]}
              </Form.Checkbox>
            ))}
          </Form.CheckboxSet>
        </Form.Group>
      );
    default:
      return <div style={{ color: 'red' }}>{_('This field cannot be displayed.')}</div>;
  }
};

const MultiWidget = ({
  input,
  meta,
  label,
  description,
  widget,
  required,
  placeholder,
  options = {},
}) => {
  return (
    <Form.Row style={{ borderBottom: '1px solid #ececec' }}>
      <Form.Group
        as={Col}
        name={input.name}
        controlId={input.name}
        isInvalid={meta.invalid}
        isValid={!!input.value && meta.touched && meta.valid}
      >
        {label && <Form.Label>{label}</Form.Label>}

        <WidgetSwitch
          {...input}
          id={input.name}
          widget={widget}
          placeholder={placeholder}
          options={options}
        />

        {description && <Form.Text muted>{description}</Form.Text>}

        {meta.error && (
          // TRICKERY NOTE: sync validation happens in `validators.js#runSyncValidation`
          <Form.Control.Feedback className="invalid-feedback" hasIcon={false} type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Form.Row>
  );
};

MultiWidget.propTypes = {};

export default MultiWidget;
