/**
 * Waits for a target DOM element and fires provided callback with that element.
 * @param {*} elSelector - DOM selector
 * @param {*} cb - callback
 * @param {*} waitPeriod - ms for each attempt
 * @param {*} attempts - number before an error message appears
 */
export async function waitForIt(elSelector, cb, attempts, verbose = false, waitPeriod = 500) {
  let edxEntry;

  do {
    edxEntry = document.querySelector(elSelector);
    await new Promise((res) => setTimeout(res, waitPeriod));

    verbose && console.log('...looking for', elSelector);

    // Warn after 10 trials:
    if (typeof attempts === 'number') {
      attempts -= 1;
      if (attempts === 0) {
        console.error(
          "Can't find provided entry node! Are you sure the selector is correct?",
          elSelector,
        );
      }
    }
  } while (edxEntry == null);

  verbose && console.log('...found!', elSelector);

  return await cb(edxEntry);
}

export const MOUNT_POINTS = {
  appRoot: '#customizations-root',
  notifications: 'div.marketing-hero',
  accountBasicAccountInfoSection: '#aboutTabSections-tabpanel > div.section:nth-child(2)',
  questionnaireContent: '#content',
  globalReportContent: '#data_download',
  globalReportContentBefore: '#data_download > h3',
  profileInfoReportContent: '.reports-download-container',
  profileInfoReportContentBefore: '.reports-download-container > p:nth-child(5)',
  discussionsReportContent: '.reports-download-container',
  discussionsReportContentBefore: '.reports-download-container > h3.hd.hd-3:nth-of-type(2)',
  supportContent: '#content',
};
