import * as R from 'ramda';
import { reduxForm } from 'redux-form';

export const OCCUPATION_OPTIONS = { teacher: 'teacher', student: 'student', pupil: 'pupil' };

/**
 * keys - fields to reset
 * values - trigger fields, field default value
 */
export const RESET_TRIGGERS = {
  custom_high_school: {
    triggers: ['country', 'occupation'],
    defaultValue: '',
  },
  custom_student_university: {
    triggers: ['country', 'occupation'],
    defaultValue: '',
  },
  custom_last_university: {
    triggers: ['country', 'occupation'],
    defaultValue: '',
  },
  last_university: {
    triggers: ['city', 'country', 'occupation'],
    defaultValue: '',
  },
  student_university: {
    triggers: ['city', 'country', 'occupation'],
    defaultValue: '',
  },
  high_school: {
    triggers: ['city', 'country', 'occupation'],
    defaultValue: '',
  },
  region: {
    triggers: ['country'],
    defaultValue: '',
  },
  city: {
    triggers: ['country', 'region'],
    defaultValue: '',
  },
  graduated_studies: {
    triggers: ['occupation'],
    defaultValue: [],
  },
};

/**
 * Form util that decides if Field should display its modal Warning.
 * @param {*} name - field name to check
 * @param {*} modal - Warning component is present
 * @param {*} value - field has value
 */
export const shouldFieldWarn = (name, value, modal, formValues) => {
  // nothing to display:
  if (!modal) {
    return false;
  }

  // no filled data at this point:
  if (!value) {
    return false;
  }

  // display modal only when changing this field triggered other not empty fields
  const listeners = Object.entries(RESET_TRIGGERS).map((entry) => {
    const [fieldId, resetConfig] = entry;

    if (
      // if any other not empty field "listens" to me :
      resetConfig.triggers.includes(name) &&
      !(R.isEmpty(formValues[fieldId]) || R.isNil(formValues[fieldId]))
    ) {
      return true;
    }
    return false;
  });
  return R.any((l) => R.equals(true, l))(listeners);
};

const AccountForm = ({ onSubmit, children }) => {
  return <form onSubmit={onSubmit}>{children}</form>;
};

AccountForm.propTypes = {};

export default reduxForm({})(AccountForm);
