import { gettext as _ } from 'core/utils';
import { useParams } from 'react-router-dom';
import styles from '../reports.module.scss';
import ReportMessage from 'components/ReportMessage';
import InsertPortal from 'core/async/InsertPortal';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import ReportsFallback from 'reports/ReportsFallback';
import { useProfileInfoReportMutation } from 'core/services/lms';

const ProfileInformationReport = ({ node, insertBefore }) => {
  const [postProfileInfoReport, result] = useProfileInfoReportMutation();
  const { courseId } = useParams();

  return (
    <InsertPortal node={node} insertBefore={insertBefore}>
      <>
        <p style={{ marginTop: '20px' }}>
          {_(
            'Click to generate a CSV file of all students enrolled in this course, along with profile information such as email address and username:',
          )}
        </p>
        <p>
          <input
            type="button"
            value={_('Download profile information as a CSV')}
            onClick={() => postProfileInfoReport({ course_id: courseId })}
            style={{ marginBottom: '33px' }}
          />
        </p>
        {result.isSuccess && (
          <ReportMessage text={result.data.status} className={styles['success-message']} />
        )}
      </>
    </InsertPortal>
  );
};

ProfileInformationReport.propTypes = {
  node: PropTypes.object.isRequired,
  insertBefore: PropTypes.object,
};

export default withErrorBoundary(ProfileInformationReport, { FallbackComponent: ReportsFallback });
