import React from 'react';
import PropTypes from 'prop-types';
import { gettext as _ } from 'core/utils';
import styled from 'styled-components';

const Heading = styled.div`
  margin: 0 0 23px;
`;

const Header = ({
  heading = _('Questionnaire'),
  description = _(
    'Please share with us the general information that we need for statistics by filling out a simple questionnaire.',
  ),
}) => {
  return (
    <Heading>
      <h3 className="questionnaire-page-title">{heading}</h3>
      <p className="questionnaire-page-description">{description}</p>
    </Heading>
  );
};

Header.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
};

export default Header;
