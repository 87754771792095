import { shouldFieldWarn } from 'accountAddon/AccountForm';
import AccountFieldMessage from 'components/AccountFieldMessage';
import { openModal } from 'core/modals/modals.slice';
import { useGetAccountQuery } from 'core/services/lms';
import { gettext as _ } from 'core/utils';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, reset, change } from 'redux-form';
import { removeSuccessMessageField } from 'accountAddon/accountAddon.slice';

/**
 * Generic Account field container.
 */
const AccountField = ({
  input: { name, value, onChange, onBlur },
  meta: { touched, warning, error, asyncValidating },
  id,
  label = '',
  description = '',
  message = '',
  options = [],
  disabled,
  fieldType,
  placeholder = '',
  required = false,
  modal,
  search,
  displayCondition = (formValues) => true,
}) => {
  const Component = fieldType;
  const accountFormValues = useSelector(getFormValues('account'));
  const { successMessageFields } = useSelector((store) => store.accountAddon);
  const { isLoading } = useGetAccountQuery();
  const dispatch = useDispatch();

  if (!displayCondition(accountFormValues)) {
    return null;
  }

  let messageIcon = null;
  if (isLoading) {
    message = _('Loading');
    messageIcon = 'fa fa-spinner fa-pulse message-in-progress';
  } else if (asyncValidating) {
    message = _('Saving');
    messageIcon = 'fa fa-spinner fa-pulse message-in-progress';
  } else if (successMessageFields.includes(name)) {
    message = _('Your changes have been saved.');
    messageIcon = 'fa fa-check message-success';
    setTimeout(() => dispatch(removeSuccessMessageField(name)), 5000);
  }

  const handleChange = (evt) => {
    if (shouldFieldWarn(name, value, modal, accountFormValues)) {
      evt.persist();

      dispatch(
        openModal({
          content: modal,
          // keeps original value for the further API update from the modal:
          context: { [name]: evt.target.value },
          onCancel: () => {
            dispatch(reset('account'));
          },
        }),
      );
      // update form state but don't sync via API:
      dispatch(change('account', name, evt.target.value));
    } else {
      onChange(evt);
    }
  };

  return (
    <div className="u-field">
      <Component
        id={id}
        label={label}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        options={options}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        search={search}
      />

      <AccountFieldMessage
        id={`message-${id}`}
        message={(touched && error) || warning || message}
        iconClassName={messageIcon}
        help={description}
      />
    </div>
  );
};

AccountField.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  option: PropTypes.array,
};

export default AccountField;
