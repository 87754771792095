import Section from 'components/Section';
import { gettext as _ } from 'core/utils';
import React from 'react';
import { Field } from 'redux-form';
import AccountField from 'components/AccountField';
import AccountText from 'components/AccountText';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import styled from 'styled-components';
import { OCCUPATION_OPTIONS } from 'accountAddon/AccountForm';
import AccountSelect from 'components/AccountSelect';
import AccountCheckboxes from 'components/AccountCheckboxes';
import QuestionnaireIncompleteNotification from 'core/notifications/QuestionnaireIncompleteNotification';
import { useGetQuestionnaireCompletenessStateQuery } from 'core/services/lms';
import QuestionnaireCompletedNotification from 'core/notifications/QuestionnaireCompletedNotification';
import QuestionnairePartialNotification from 'core/notifications/QuestionnairePartialNotification';

const StyledDiv = styled.div`
  &:last-child {
    border-bottom: none;
  }
`;

// it must be out of component because it lost focus every typing due to rerender field
const renderTextField = (props) => <AccountField {...props} fieldType={AccountText} />;
const renderSelectField = (props) => <AccountField {...props} fieldType={AccountSelect} />;
const renderCheckboxesField = (props) => <AccountField {...props} fieldType={AccountCheckboxes} />;

const EducationInfo = ({ fields = {} }) => {
  const accountFormValues = useSelector(getFormValues('account'));
  const { data: questionnaireCompleteness = {}, isQuestionnaireCompletenessLoading } =
    useGetQuestionnaireCompletenessStateQuery();

  if (isQuestionnaireCompletenessLoading) {
    return null;
  }

  let questionnaireNotification = null;
  switch (questionnaireCompleteness.status) {
    case 'completed':
      questionnaireNotification = <QuestionnaireCompletedNotification />;
      break;
    case 'partial':
      questionnaireNotification = <QuestionnairePartialNotification />;
      break;
    default:
      questionnaireNotification = <QuestionnaireIncompleteNotification />;
      break;
  }

  return (
    <Section
      heading={_('Education Information')}
      description={_('These settings include basic information about your account.')}
    >
      <StyledDiv>
        <Field
          id="last_university"
          name="last_university"
          component={renderSelectField}
          label={_('University (last graduated)')}
          placeholder={_('Select University')}
          options={fields.last_university?.options}
          required={fields.last_university?.required}
          displayCondition={(formValues) =>
            formValues?.country === 'RO' && formValues?.occupation === OCCUPATION_OPTIONS.teacher
          }
        />
        <Field
          id="custom_last_university"
          name="custom_last_university"
          component={renderTextField}
          label={_('University (last graduated)')}
          placeholder={_('University Name')}
          required={fields.custom_last_university?.required}
          displayCondition={(formValues) =>
            formValues?.country !== 'RO' && formValues?.occupation === OCCUPATION_OPTIONS.teacher
          }
        />
        <Field
          id="high_school"
          name="high_school"
          component={renderSelectField}
          label={_('High-School')}
          placeholder={_('Select High School')}
          options={fields.high_school?.options}
          description={_(
            "If you don't see an establishment on the list, please check your region and town information or contact our support.",
          )}
          required={fields.high_school?.required}
          displayCondition={(formValues) =>
            formValues?.country === 'RO' && formValues?.occupation === OCCUPATION_OPTIONS.pupil
          }
          search={true}
        />
        <Field
          id="custom_high_school"
          name="custom_high_school"
          component={renderTextField}
          label={_('High-School')}
          placeholder={_('Select High School')}
          required={fields.custom_high_school?.required}
          displayCondition={(formValues) =>
            formValues?.country !== 'RO' && formValues?.occupation === OCCUPATION_OPTIONS.pupil
          }
        />
        <Field
          id="student_university"
          name="student_university"
          component={renderSelectField}
          label={_('University')}
          placeholder={_('Select University')}
          options={fields.student_university?.options}
          required={fields.student_university?.required}
          description={_(
            "If you don't see an establishment on the list, please check your region and town information or contact our support.",
          )}
          displayCondition={(formValues) =>
            formValues?.country === 'RO' && formValues?.occupation === OCCUPATION_OPTIONS.student
          }
        />
        <Field
          id="custom_student_university"
          name="custom_student_university"
          component={renderTextField}
          label={_('University')}
          placeholder={_('University Name')}
          required={fields.custom_student_university?.required}
          displayCondition={(formValues) =>
            formValues?.country !== 'RO' && formValues?.occupation === OCCUPATION_OPTIONS.student
          }
        />
        <Field
          id="graduated_studies"
          name="graduated_studies"
          component={renderCheckboxesField}
          label={_('Graduated studies')}
          options={fields.graduated_studies?.options}
          required={fields.graduated_studies?.required}
          displayCondition={(formValues) => formValues?.occupation === OCCUPATION_OPTIONS.teacher}
        />
      </StyledDiv>
      {accountFormValues?.occupation === OCCUPATION_OPTIONS.teacher && questionnaireNotification}
    </Section>
  );
};

EducationInfo.propTypes = {};

export default EducationInfo;
