import { gettext as _ } from 'core/utils';
import React from 'react';
import WarningModal from 'core/modals/WarningModal';

const CountryChangeWarning = (props) => {
  return (
    <WarningModal
      alt={_('Country warning')}
      description={_(
        'Changing the country will affect your account information. You will need to update information related to your country.',
      )}
      header={_('Are you sure you want to change country?')}
    />
  );
};

CountryChangeWarning.propTypes = {};

export default CountryChangeWarning;
