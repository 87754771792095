import styled from 'styled-components';

const StyledFallBack = styled.div``;

export function AccountAddonFallback(props) {
  return (
    <StyledFallBack>
      <div>!!!</div>
    </StyledFallBack>
  );
}

export default AccountAddonFallback;
