import { gettext as _ } from 'core/utils';
import { useDiscussionsReportMutation } from 'core/services/lms';
import { useParams } from 'react-router-dom';
import styles from '../reports.module.scss';
import ReportMessage from 'components/ReportMessage';
import InsertPortal from 'core/async/InsertPortal';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import ReportsFallback from 'reports/ReportsFallback';
import { useRef } from 'react';


const DiscussionsReport = ({ node, insertBefore }) => {
  const [discussionsReport, result] = useDiscussionsReportMutation();
  const { courseId } = useParams();
  const discussionId = useRef('');

  return (
    <InsertPortal node={node} insertBefore={insertBefore}>
      <div className={styles['report']}>
        <h3 className="hd hd-3">{_('Discussions report')}</h3>
        <p>
          {_(
            'In this report, you will download the content of all discussions or a specific discussion within this course. To download a specific discussion, enter the discussion ID below. Otherwise, leave the field blank.',
          )}
        </p>
        <p>
          <button
            type="button"
            className="btn mr-2"
            onClick={() => discussionsReport({ course_id: courseId, discussion_id: discussionId.current.value })}
          >{_('Download discussions report')}</button>
          <input className={styles['report-input']} ref={discussionId} />
        </p>
        {result.isSuccess && (
          <ReportMessage text={result.data.status} className={styles['success-message']} />
        )}
        {result.isError && result.error.status === 400 && (
          <ReportMessage text={result.error.data.status} className={styles['error-message']} />
        )}
      </div>
      <hr />
    </InsertPortal>
  );
};

DiscussionsReport.propTypes = {
  node: PropTypes.object.isRequired,
  insertBefore: PropTypes.object,
};

export default withErrorBoundary(DiscussionsReport, { FallbackComponent: ReportsFallback });
