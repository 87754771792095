import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Portal } from 'react-portal';
import { canUseDOM } from './utils';

class InsertPortal extends Portal {
  componentWillUnmount() {
    if (this.defaultNode) {
      document.body.removeChild(this.defaultNode);
    }
    this.defaultNode = null;
  }

  render() {
    if (!canUseDOM) {
      return null;
    }

    if (!this.props.node && !this.defaultNode) {
      this.defaultNode = document.createElement('div');
      document.body.appendChild(this.defaultNode);
    }

    if (this.props.node && this.props.insertBefore) {
      const beforeNode = document.createElement('div');
      this.props.node.insertBefore(beforeNode, this.props.insertBefore);
      return ReactDOM.createPortal(this.props.children, beforeNode);
    }

    return ReactDOM.createPortal(this.props.children, this.props.node || this.defaultNode);
  }
}

InsertPortal.propTypes = {
  children: PropTypes.node.isRequired,
  node: PropTypes.any,
  insertBefore: PropTypes.any,
};

export default InsertPortal;
