import { gettext as _ } from 'core/utils';
import React from 'react';
import WarningModal from 'core/modals/WarningModal';

const OccupationChangeWarning = (props) => {
  return (
    <WarningModal
      alt={_('Occupation warning')}
      description={_(
        'Changing the occupation will affect your account information. You will need to update information related to your occupation.',
      )}
      header={_('Are you sure you want to change occupation?')}
    />
  );
};

OccupationChangeWarning.propTypes = {};

export default OccupationChangeWarning;
