import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { enableMapSet } from 'immer';
import history from './history';
import configureMiddlewares from './middlewares';
import appReducer from './reducers';

enableMapSet(); // `Set` object support;

const store = configureStore({
  reducer: appReducer(history),
  middleware: configureMiddlewares,
});

// RTK Query refetch:
setupListeners(store.dispatch);

export default store;
