import NotificationAlert from 'components/NotificationAlert';
import { ROUTES } from 'core/routing';
import { gettext as _ } from 'core/utils';
import React from 'react';
import { useGetAccountCompletenessStateQuery } from 'core/services/lms';

const AccountIncompleteNotification = (props) => {
  const { data, isSuccess } = useGetAccountCompletenessStateQuery();

  return (
    isSuccess &&
    !data.completed && (
      <NotificationAlert
        heading={_('Complete your account information')}
        message={_(
          'To start learning please complete all required information on your account page.',
        )}
        icon="octagon"
        action={{
          label: _('Account Information'),
          href: ROUTES.accountSettingsPage,
        }}
      />
    )
  );
};

AccountIncompleteNotification.propTypes = {};

export default AccountIncompleteNotification;
