import AccountFieldLabel from 'components/AccountFieldLabel';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import styles from './Checkboxes.module.scss';

const CheckboxesWrapper = styled.div`
  margin-bottom: 20px;
`;

const AccountCheckboxes = ({
  label,
  name,
  value,
  onChange,
  options = [],
  disabled,
  required = false,
}) => {
  return (
    <CheckboxesWrapper className="field">
      <AccountFieldLabel
        label={label}
        fieldFor={name}
        className={styles['checkboxes-title']}
        required={required}
      />
      <ul className={styles['checkboxes-list']}>
        {options.map((opt, index) => (
          <li key={opt.value} className="mb-1.5 d-flex">
            <input
              className={styles['custom-checkbox']}
              id={'id_checkbox_' + index}
              type="checkbox"
              name={name}
              value={opt.value}
              checked={value.includes(opt.value)}
              disabled={disabled}
              onChange={(e) => {
                const currentValue = e.target.value;
                let newValue;

                if (e.target.checked) {
                  newValue = [...value, currentValue];
                } else {
                  newValue = value.filter((item) => item !== currentValue);
                }

                onChange(newValue);
              }}
            />
            <label className={styles.label} htmlFor={'id_checkbox_' + index}>
              {opt.name}
            </label>
          </li>
        ))}
      </ul>
    </CheckboxesWrapper>
  );
};

AccountCheckboxes.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
};

export default AccountCheckboxes;
