import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { Portal } from 'react-portal';
import SupportFallback from './SupportFallback';
import GuestSupport from './GuestSupport';
import AuthenticatedSupport from './AuthenticatedSupport';
import './support.scss';

const Support = ({ node }) => {
  const supportEmail = window.platformSettings.platform.supportEmail;
  const userIsAuthenticated = window.platformSettings.user.isAuthenticated;

  return (
    <Portal node={node}>
      <div className="support-container">
        {userIsAuthenticated ? (
          <AuthenticatedSupport />
        ) : (
          <GuestSupport supportEmail={supportEmail} />
        )}
      </div>
    </Portal>
  );
};

Support.propTypes = {
  node: PropTypes.object.isRequired,
};

export default withErrorBoundary(Support, { FallbackComponent: SupportFallback });
