import { push } from 'connected-react-router';
import WarningModal from 'core/modals/WarningModal';
import { ROUTES } from 'core/routing';
import {
  QuestionnaireCompleteness,
  useGetQuestionnaireCompletenessStateQuery,
} from 'core/services/lms';
import { gettext as _ } from 'core/utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { openModal } from '../modals.slice';
import { ReactComponent as QuestionnaireBanner } from './change-questionnaire.svg';

/**
 * Decides whether to display a modal about Questionnaire incompletion.
 */
const IncompleteQuestionnaireWarning = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data: questionnaireCompleteness = {} } = useGetQuestionnaireCompletenessStateQuery();

  useEffect(() => {
    const displayDialog = location.hash === '#incomplete-questionnaire';

    // mute until status is defined:
    if (questionnaireCompleteness.status == null) {
      return
    }

    if (questionnaireCompleteness.status !== QuestionnaireCompleteness.completed && displayDialog) {
      dispatch(
        openModal({
          content: () => (
            <WarningModal
              banner={<QuestionnaireBanner />}
              alt={_('Questionnaire Modal')}
              description={_(
                'This course is absolutely free for all users. Please, share with us the general information we need for statistics by filling in a simple questionnaire.',
              )}
              header={_('Fill in the questionnaire')}
              primaryButtonText={_('Questionnaire')}
            />
          ),
          onOk: () => {
            window.location.href = ROUTES.questionnairePage;
          },
          onCancel: () => dispatch(push()),
        }),
      );
    }
  }, [dispatch, questionnaireCompleteness, location]);

  return null;
};

IncompleteQuestionnaireWarning.propTypes = {};

export default IncompleteQuestionnaireWarning;
