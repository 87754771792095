import AccountFieldLabel from 'components/AccountFieldLabel';
import PropTypes from 'prop-types';
import React from 'react';
import SelectSearch from 'react-select-search';
import { gettext as _ } from 'core/utils';
import * as R from 'ramda';

const AccountSelect = ({
  id,
  label,
  name,
  value,
  onChange,
  options = [],
  placeholder = '',
  required = false,
  disabled,
  search = false,
  emptyPlaceholder = _('There are no options'),
  emptySearchResults = _('Nothing found'),
}) => {
  if (search) {
    return (
      <div className="u-field-value field">
        <AccountFieldLabel label={label} fieldFor={name} required={required} />
        <SelectSearch
          id={id}
          name={name}
          value={value}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          search
          autoComplete="on"
          emptyMessage={emptySearchResults}
          getOptions={(query) =>
            R.filter((opt) => R.startsWith(query.toLowerCase(), opt.name.toLowerCase()), options)
          }
        />
      </div>
    );
  }

  return (
    <div className="u-field-value field">
      <AccountFieldLabel label={label} fieldFor={name} required={required} />

      <select name={name} id={id} value={value} onChange={onChange} disabled={disabled}>
        {!(required && value) && <option value={null}>{placeholder}</option>}
        {options.map((opt) => (
          <option value={opt.value} key={opt.value}>
            {opt.name}
          </option>
        ))}
      </select>
    </div>
  );
};

AccountSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  search: PropTypes.bool,
};

export default AccountSelect;
