import AccountAddon from 'accountAddon';
import { MOUNT_POINTS } from 'core/async';
import Await from 'core/async/Await';
import IncompleteAccountWarning from 'core/modals/IncompleteAccountWarning';
import IncompleteQuestionnaireWarning from 'core/modals/IncompleteQuestionnaireWarning';
import { ROUTES } from 'core/routing';
import Questionnaire from 'questionnaire';
import { Route, Switch } from 'react-router-dom';
import GlobalReport from 'reports/GlobalReport';
import ProfileInformationReport from 'reports/ProfileInformationReport';
import DiscussionsReport from 'reports/DiscussionsReport';
import Support from 'support';

const Routes = (props) => (
  <>
    <Switch>
      <Route
        path={ROUTES.accountSettingsPage}
        exact
        render={() => (
          <Await
            target={MOUNT_POINTS.accountBasicAccountInfoSection}
            then={(node) => <AccountAddon node={node} />}
          />
        )}
      />
      <Route
        path={ROUTES.questionnairePage}
        exact
        render={() => (
          <Await
            target={MOUNT_POINTS.questionnaireContent}
            then={(node) => <Questionnaire node={node} />}
          />
        )}
      />
      <Route
        path={ROUTES.supportPage}
        exact
        render={() => (
          <Await target={MOUNT_POINTS.supportContent} then={(node) => <Support node={node} />} />
        )}
      />
    </Switch>
    <Route
      path={ROUTES.courseAboutPage}
      exact
      render={(routeProps) => <IncompleteAccountWarning {...routeProps} />}
    />
    <Route
      path={ROUTES.courseAboutPage}
      exact
      render={(routeProps) => <IncompleteQuestionnaireWarning {...routeProps} />}
    />
    <Route
      path={ROUTES.instructorDashboardPage}
      exact
      render={() => (
        <Await
          target={MOUNT_POINTS.profileInfoReportContent}
          before={MOUNT_POINTS.profileInfoReportContentBefore}
          then={(node, before) => <ProfileInformationReport node={node} insertBefore={before} />}
        />
      )}
    />
    <Route
      path={ROUTES.instructorDashboardPage}
      exact
      render={() => (
        <Await
          target={MOUNT_POINTS.globalReportContent}
          before={MOUNT_POINTS.globalReportContentBefore}
          then={(node, before) => <GlobalReport node={node} insertBefore={before} />}
        />
      )}
    />
    <Route
      path={ROUTES.instructorDashboardPage}
      exact
      render={() => (
        <Await
          target={MOUNT_POINTS.discussionsReportContent}
          before={MOUNT_POINTS.discussionsReportContentBefore}
          then={(node, before) => <DiscussionsReport node={node} insertBefore={before} />}
        />
      )}
    />
  </>
);

Routes.propTypes = {};

export default Routes;
