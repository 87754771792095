import SupportTextField from 'support/SupportTextField';
import { gettext as _ } from 'core/utils';
import { Field, reduxForm } from 'redux-form';

// FIXME: move these validate functions to other place
const maxLength = (max) => (value) =>
  value && value.length > max ? _(`Must be ${max} characters or less`) : undefined;
const minLength = (min) => (value) =>
  value && value < min ? _(`Must be at least ${min}`) : undefined;

const required = (value) => (value ? undefined : _('This field is required'));
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? _('Invalid email format')
    : undefined;

const maxLength255 = maxLength(255);
const maxLength1000 = maxLength(1000);
const minLength1 = minLength(1);

const SupportForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit} className="support-form" noValidate>
      <Field
        id="subject"
        name="subject"
        type="text"
        component={SupportTextField}
        label={_('Subject')}
        placeholder={_('Enter the subject of your request')}
        validate={[required, maxLength255, minLength1]}
        required={true}
      />

      <Field
        id="description"
        name="description"
        type="textarea"
        component={SupportTextField}
        label={_('Description')}
        placeholder={_('Enter the description of your request')}
        validate={[required, maxLength1000, minLength1]}
        required={true}
      />

      <Field
        id="email"
        name="email"
        type="text"
        component={SupportTextField}
        label={_('Preferable email for communication')}
        placeholder={_('Enter your email')}
        validate={[required, email]}
        required={true}
      />
      <button className="support-btn" type="submit">
        {_('Send')}
      </button>
    </form>
  );
};

SupportForm.propTypes = {};

export default reduxForm({
  form: 'support',
})(SupportForm);
