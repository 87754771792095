import React from 'react';
import { reduxForm } from 'redux-form';

const QuestionnaireForm = ({ onSubmit, children }) => {
  return <form onSubmit={onSubmit}>{children}</form>;
};

QuestionnaireForm.propTypes = {};

export default reduxForm({})(QuestionnaireForm);
