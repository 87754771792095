import { gettext as _ } from 'core/utils';
import React from 'react';
import WarningModal from 'core/modals/WarningModal';

const TownChangeWarning = (props) => {
  return (
    <WarningModal
      alt={_('Town warning')}
      description={_(
        'Changing the town will affect your account information. You will need to update information related to your town.',
      )}
      header={_('Are you sure you want to change town?')}
    />
  );
};

TownChangeWarning.propTypes = {};

export default TownChangeWarning;
