import { waitForIt } from 'core/async';
import PropTypes from 'prop-types';
import { useState } from 'react';

/**
 * This component tries to attach to DOM for a number of attempts and only then gives up.
 * @param {target} a parent element to become the last child of
 * @param {before} a sibling element to precede
 * @returns
 */
const Await = ({ target, before, then, attempts = 0, verbose = false }) => {
  const [targetNode, updateTargetNode] = useState(null);
  const [beforeNode, updateBeforeNode] = useState(null);

  if (verbose) {
    console.log(`Looking for ${target} in ${attempts} attemps...`);
  }

  waitForIt(target, updateTargetNode, attempts, verbose);

  if (!before) {
    return targetNode && then(targetNode);
  }

  waitForIt(before, updateBeforeNode, attempts, verbose);
  return beforeNode && targetNode && then(targetNode, beforeNode);
};

Await.propTypes = {
  target: PropTypes.string.isRequired,
  then: PropTypes.func.isRequired,
  attempts: PropTypes.number,
};

export default Await;
