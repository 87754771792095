import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSectionConnector = styled.div`
  border-top: 2px solid #f5f5f5;
  margin-top: -40px;
`;

const SectionConnector = ({
  className = 'account-settings-section-body aboutTabSections-section-body',
  children,
}) => <StyledSectionConnector className={className}>{children}</StyledSectionConnector>;

SectionConnector.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionConnector;
