import { MOUNT_POINTS } from 'core/async';
import Await from 'core/async/Await';
import Modal from 'core/modals/Modal';
import Notifications from 'core/notifications';
import Routes from 'core/routing/Routes';
import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {}
`;

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <Modal />
      {window.platformSettings.user.isAuthenticated && (
        <Await target={MOUNT_POINTS.notifications} then={(node) => <Notifications node={node} />} />
      )}
      <Routes />
    </>
  );
};

export default App;
