import AccountFieldLabel from 'components/AccountFieldLabel';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TextWrapper = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input.attrs((props) => ({
  type: 'text',
}))`
  height: 48px !important;
  padding: 0.625rem !important;
  font-size: 0.875em !important;
`;

const AccountText = ({
  id,
  label,
  name,
  value,
  onChange,
  placeholder,
  onBlur,
  defaultOption = '',
  disabled,
  required = false,
}) => {
  return (
    <TextWrapper className="u-field-value field">
      <AccountFieldLabel label={label} fieldFor={name} required={required} />

      <Input
        name={name}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
        value={value}
      />
    </TextWrapper>
  );
};

AccountText.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
};

export default AccountText;
