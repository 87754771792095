import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const ReportMessage = ({ text, className = '', timeout = 5000 }) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, timeout);
    return () => clearTimeout(timer);
  }, [timeout]);

  return show ? <div className={className}>{text}</div> : null;
};

ReportMessage.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  timeout: PropTypes.number,
};

export default ReportMessage;
