import { Form } from '@edx/paragon';
import * as R from 'ramda';
import { gettext as _ } from 'core/utils';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css';

const emptyPlaceholder = _('There are no options');
const emptySearchResults = _('Nothing found');

const SelectField = ({ id, name, value, onChange, placeholder, options = {}, search = false }) => {
  const emptyOptions = R.isEmpty(options);

  if (search) {
    const listOptions = R.map((opt) => ({ name: opt[1], value: opt[0] }), R.toPairs(options));
    return (
      <SelectSearch
        id={id}
        name={name}
        value={value}
        options={listOptions}
        placeholder={placeholder}
        onChange={onChange}
        disabled={emptyOptions}
        search
        autoComplete="on"
        emptyMessage={emptySearchResults}
        getOptions={(query) =>
          R.filter((opt) => R.startsWith(query.toLowerCase(), opt.name.toLowerCase()), listOptions)
        }
      />
    );
  }

  return (
    <Form.Control
      as="select"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      disabled={emptyOptions}
    >
      {!value && <option value={null}>{emptyOptions ? emptyPlaceholder : placeholder}</option>}
      {Object.keys(options).map((opt) => (
        <option value={opt} key={opt}>
          {options[opt]}
        </option>
      ))}
    </Form.Control>
  );
};

SelectField.propTypes = {};

export default SelectField;
