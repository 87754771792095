import React from 'react';
import PropTypes from 'prop-types';
import styles from './Buttons.module.scss';
import { gettext as _ } from 'core/utils';

const Buttons = ({
  primaryLabel = _('Ok'),
  secondaryLabel = _('Cancel'),
  primaryHandler,
  secondaryHandler,
  primaryDisabled,
  secondaryDisabled,
  primaryIcon,
  secondaryIcon,
}) => {
  return (
    <div className={styles.container}>
      {secondaryLabel && (
        <button
          disabled={secondaryDisabled || !secondaryHandler}
          onClick={secondaryHandler}
          className={styles.secondary}
        >
          {secondaryIcon}
          {secondaryLabel}
        </button>
      )}
      <button
        disabled={primaryDisabled || !primaryHandler}
        onClick={primaryHandler}
        className={styles.primary}
      >
        {primaryLabel}
        {primaryIcon}
      </button>
    </div>
  );
};

Buttons.propTypes = {
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  primaryHandler: PropTypes.func,
  secondaryHandler: PropTypes.func,
  primaryIcon: PropTypes.node,
  secondaryIcon: PropTypes.node,
};

export default Buttons;
