import Buttons from 'components/Buttons';
import { useGetQuestionnaireQuery } from 'core/services/lms';
import { gettext as _, isNilOrEmpty } from 'core/utils';
import { setTab } from 'questionnaire/questionnaire.slice';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as NextArrow } from './next.svg';
import { ReactComponent as PrevArrow } from './prev.svg';
import { ROUTES } from 'core/routing';
import PropTypes from 'prop-types';

const Footer = ({fieldValues}) => {
  const dispatch = useDispatch();
  const { data: fieldSets = {} } = useGetQuestionnaireQuery();
  const { currentTab } = useSelector((store) => store.questionnaire);

  const tabs = Object.keys(fieldSets);

  const firstTab = R.head(tabs) === currentTab;
  const lastTab = R.last(tabs) === currentTab;

  const handleNext = () => {
    !lastTab && dispatch(setTab(tabs[R.indexOf(currentTab, tabs) + 1]));
  };

  const handleBack = () => {
    !firstTab && dispatch(setTab(tabs[R.indexOf(currentTab, tabs) - 1]));
  };

  const handleFinish = () => {
    window.location.href = ROUTES.coursesPage;
  }

  return (
    <Buttons
      primaryLabel={lastTab ? _('Finish') : _('Next tab')}
      primaryDisabled={lastTab && R.any(isNilOrEmpty)(fieldValues)}
      primaryHandler={lastTab ? handleFinish : handleNext}
      primaryIcon={<NextArrow/>}
      secondaryLabel={_('Previous tab')}
      secondaryDisabled={firstTab}
      secondaryHandler={handleBack}
      secondaryIcon={<PrevArrow/>}
    />
  );
};

Footer.propTypes = {
  fieldValues: PropTypes.array.isRequired,
};

export default Footer;
