import { LOCATION_CHANGE } from 'connected-react-router';
import { closeModal } from './modals.slice';

const modalMiddleware = (storeAPI) => (next) => (action) => {
  const doModalClose = () => storeAPI.dispatch(closeModal());

  // Close a modal on routing if it is open:
  if (action.type === LOCATION_CHANGE) {
    const { isOpen } = storeAPI.getState().modal;
    if (isOpen) {
      doModalClose();
    }
  }

  return next(action);
};

export default modalMiddleware;
