import PropTypes from 'prop-types';
import React from 'react';

const SupportTextField = ({ id, name, input, label, required, placeholder, type, meta }) => {
  const error = meta.error && meta.touched && meta.error;

  return (
    <div className="support-form-row">
      <label className={`support-form-label ${error ? 'error' : ''}`} htmlFor={name}>
        {label}
        {required ? '*' : null}
      </label>
      {type === 'textarea' ? (
        <textarea
          className={`support-form-textarea ${error ? 'error' : ''}`}
          {...input}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
        />
      ) : (
        <input
          className={`support-form-input ${error ? 'error' : ''}`}
          {...input}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
        />
      )}
      {error && <span className="support-form-error">{error}</span>}
    </div>
  );
};

SupportTextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default SupportTextField;
