import { push } from 'connected-react-router';
import WarningModal from 'core/modals/WarningModal';
import { ROUTES } from 'core/routing';
import { useGetAccountCompletenessStateQuery } from 'core/services/lms';
import { gettext as _ } from 'core/utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { openModal } from '../modals.slice';

/**
 * Decides whether to display a modal about Account incompletion.
 */
const IncompleteAccountWarning = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data: accountCompleteness = {} } = useGetAccountCompletenessStateQuery();

  useEffect(() => {
    const displayDialog = location.hash === '#incomplete-account';

    if (!accountCompleteness.completed && displayDialog) {
      dispatch(
        openModal({
          content: () => (
            <WarningModal
              alt={_('Account Modal')}
              description={_(
                'To start the course please add all of the required information about the user on your account page.',
              )}
              header={_('Complete your account information')}
              primaryButtonText={_('Account')}
            />
          ),
          onOk: () => {
            window.location.href = ROUTES.accountSettingsPage;
          },
          onCancel: () => dispatch(push()),
        }),
      );
    }
  }, [dispatch, accountCompleteness, location]);

  return null;
};

IncompleteAccountWarning.propTypes = {};

export default IncompleteAccountWarning;
