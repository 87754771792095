import { gettext as _ } from 'core/utils';
import { format } from 'react-string-format';

const GuestSupport = ({ supportEmail }) => {
  const message = _(
    'You need to register or log in to send us an email via support form. If you have problems with log in or registration process - contact us on {0}.',
  );

  return (
    <div>
      <h1>{_('Contact us')}</h1>
      <p>{format(message, <strong>{supportEmail}</strong>)}</p>
    </div>
  );
};

GuestSupport.propTypes = {};

export default GuestSupport;
