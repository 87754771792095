import React from 'react';
import PropTypes from 'prop-types';

const AccountFieldMessage = ({ id, iconClassName = null, message = '', help = '' }) => {
  return (
    <span className="u-field-message" id={id}>
      <span className="u-field-message-notification" aria-live="polite">
        <span className={iconClassName} aria-hidden="true"></span>
        <span className="sr"></span>
        {message}
      </span>
      <span className="u-field-message-help">{!message && help}</span>
    </span>
  );
};

AccountFieldMessage.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  help: PropTypes.string,
};

export default AccountFieldMessage;
