import { Tab, Tabs } from '@edx/paragon';
import { useGetQuestionnaireQuery } from 'core/services/lms';
import * as R from 'ramda';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { setTab } from '../../questionnaire.slice';
import MultiWidget from '../MultiWidget';

const QuestionnaireTabs = (props) => {
  const dispatch = useDispatch();
  const { data: fieldSets = {} } = useGetQuestionnaireQuery();
  const { currentTab } = useSelector((store) => store.questionnaire);
  const tabs = Object.keys(fieldSets);

  useEffect(() => {
    !R.isEmpty(tabs) && !currentTab && dispatch(setTab(tabs[0]));
  }, [dispatch, tabs, currentTab]);

  const handleTabSelect = (key) => {
    dispatch(setTab(key));
  };

  return (
    <Tabs activeKey={currentTab} onSelect={(key) => handleTabSelect(key)} id="tabs">
      {tabs.map((tabId) => {
        const tabFields = fieldSets[tabId]?.fields || [];
        return (
          !R.isEmpty(tabFields) && (
            <Tab eventKey={tabId} title={fieldSets[tabId]?.label} key={tabId}>
              {tabFields.map((field) => (
                <Field {...field} component={MultiWidget} key={field.name} />
              ))}
            </Tab>
          )
        );
      })}
    </Tabs>
  );
};

QuestionnaireTabs.propTypes = {};

export default QuestionnaireTabs;
