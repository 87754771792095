import { gettext as _ } from 'core/utils';
import React from 'react';
import WarningModal from 'core/modals/WarningModal';
import { ReactComponent as Banner } from './success.svg';

const SuccessModal = (props) => {
  return (
    <WarningModal
      alt={_('Success Modal')}
      header={_('Thank you! Your request has been successfully sent.')}
      primaryButtonText={_('Okay')}
      secondaryButtonText={''}
      banner = {<Banner />}
    />
  );
};

SuccessModal.propTypes = {};

export default SuccessModal;
