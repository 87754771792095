import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTab: null,
};

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setTab: (slice, action) => {
      slice.currentTab = action.payload;
    },
  },
});

export const { setTab } = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
