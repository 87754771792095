import React from 'react';
import styled from 'styled-components';

// TODO: what is displayed if Modal window failed?

const Message = styled.div`
  color: red;
`;

const ModalFallback = (props) => {
  return <Message>{'Modal content cannot be displayed.'}</Message>;
};

ModalFallback.propTypes = {};

export default ModalFallback;
