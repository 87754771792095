import style from './SupportFormFeedback.module.scss';

const SupportFormFeedback = ({ heading, details = '' }) => (
  <div className={style.heading}>
    <h2>{heading}</h2>
    <div>{details}</div>
  </div>
);

SupportFormFeedback.propTypes = {};

export default SupportFormFeedback;
