import NotificationAlert from 'components/NotificationAlert';
import { gettext as _ } from 'core/utils';
import React from 'react';
import { format } from 'react-string-format';

const AccountActivationNotification = (props) => {
  const platformName = window.platformSettings.platform.platformName;
  const userEmail = window.platformSettings.user.email;
  const activationEmailSupportLink = window.platformSettings.platform.activationEmailSupportLink;

  const emailStart = '<strong>';
  const emailEnd = '</strong>';
  const linkStart = `<a target='_blank' href='${activationEmailSupportLink}'>`;
  const linkEnd = '</a>';

  const message = _(
    'Check your {0}{1}{2} inbox for an account activation link from {3}. If you need help, contact {4}{5} Support{6}.',
  );

  return (
      <NotificationAlert
        heading={_('Account activation')}
        message={format(message, emailStart, userEmail, emailEnd, platformName, linkStart, platformName, linkEnd)}
        icon="octagon"
      />
  );
};

AccountActivationNotification.propTypes = {};

export default AccountActivationNotification;
