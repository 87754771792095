import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';

const initialState = {
  successMessageFields: [],
};

export const accountAddonSlice = createSlice({
  name: 'accountAddon',
  initialState,
  reducers: {
    addSuccessMessageField: (slice, action) => {
      slice.successMessageFields = R.union(slice.successMessageFields, action.payload);
    },
    removeSuccessMessageField: (slice, action) => {
      slice.successMessageFields = R.reject(
        (f) => f === action.payload,
        slice.successMessageFields,
      );
    },
  },
});

export const { addSuccessMessageField, removeSuccessMessageField } = accountAddonSlice.actions;

export default accountAddonSlice.reducer;
