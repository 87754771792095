// import { required } from 'App/AccountForm/validators';
import AccountField from 'components/AccountField';
import AccountSelect from 'components/AccountSelect';
import SectionConnector from 'components/SectionConnector';
import { gettext as _ } from 'core/utils';
import React from 'react';
import { Field } from 'redux-form';
import CountryChangeWarning from 'core/modals/CountryChangeWarning';
import OccupationChangeWarning from 'core/modals/OccupationChangeWarning';
import RegionChangeWarning from 'core/modals/RegionChangeWarning';
import TownChangeWarning from 'core/modals/TownChangeWarning';
import AccountText from 'components/AccountText';

const renderTextField = (props) => <AccountField {...props} fieldType={AccountText} />;
const renderSelectField = (props) => <AccountField {...props} fieldType={AccountSelect} />;

const BasicInfo = ({ fields = {} }) => {
  return (
    <SectionConnector>
      <Field
        id="gender"
        name="gender"
        component={renderSelectField}
        label={_('Sex')}
        options={fields.gender?.options}
        required={fields.gender?.required}
      />
      <Field
        id="year_of_birth"
        name="year_of_birth"
        component={renderSelectField}
        label={_('Year of Birth')}
        options={fields.year_of_birth?.options}
        required={fields.year_of_birth?.required}
      />
      <Field
        id="phone_number"
        name="phone_number"
        component={renderTextField}
        label={_('Phone number')}
        required={fields.phone_number?.required}
      />
      <Field
        id="country"
        name="country"
        component={renderSelectField}
        label={_('Country')}
        description={_("Country where I'm studying or teaching.")}
        options={fields.country?.options}
        placeholder={_('Select Country')}
        required={fields.country?.required}
        modal={CountryChangeWarning}
      />
      <Field
        id="region"
        name="region"
        component={renderSelectField}
        label={_('Region')}
        options={fields.region?.options}
        placeholder={_('Select Region')}
        required={fields.region?.required}
        displayCondition={(formValues) => formValues?.country === 'RO'}
        modal={RegionChangeWarning}
      />
      <Field
        id="city"
        name="city"
        component={renderSelectField}
        label={_('Town')}
        options={fields.city?.options}
        placeholder={_('Select Town')}
        required={fields.city?.required}
        displayCondition={(formValues) => formValues?.country === 'RO'}
        modal={TownChangeWarning}
      />
      <Field
        id="occupation"
        name="occupation"
        component={renderSelectField}
        label={_('Occupation')}
        options={fields.occupation?.options}
        placeholder={_('Select Occupation')}
        required={fields.occupation?.required}
        displayCondition={(formValues) => Boolean(formValues?.country)}
        modal={OccupationChangeWarning}
      />
    </SectionConnector>
  );
};

BasicInfo.propTypes = {};

export default BasicInfo;
