import * as R from 'ramda';

/**
 * I18N: OeX Mako templates context includes `django.gettext` tool.
 */
export const gettext = (window.django && window.django.gettext) || ((text) => text);

/**
 * Flattens complex field object to the only `value` property value.
 */
export const pickFieldValues = (fields) =>
  R.mapObjIndexed((data) => R.path(['value'], data), fields);

/**
 * Extracts fields from deeply nested tabs.
 *
 * fieldSets = {"students_info":{"label":"Student's info","fields":[{"id":"current_students","label":"Students you teach this year","required":true,"type":"number","value":2}]}, <other tab>}
 * result = [{"id":"current_students","label":"Students you teach this year","required":true,"type":"number","value":2}, <other fields>]
 */
export const fieldsFromFieldSets = (fieldSets) =>
  R.flatten(R.map((tabValue) => tabValue.fields, R.values(fieldSets)));

// const a = {"students_info":{"label":"Student's info","fields":[{"id":"current_students","label":"Students you teach this year","required":true,"type":"number","value":2}]},"work_info":{"label":"Work info","fields":[{"id":"rural_students","label":"Rural students","required":true,"type":"select","value":"50","options":{"0-20":"under 20%","21-49":"between 21-49%","50":"50%","51-74":"between 51-74%","75-99":"above 75%","all":"all"}}]}}

/**
 * Extracts values from deeply nested tabs.
 *
 * result = [{"current_students": 2}, {<fieldId>: <fieldValue>}]
 */
export const valuesFromFieldSets = (fieldSets) =>
  R.fromPairs(R.map((field) => [field.name, field.value], fieldsFromFieldSets(fieldSets)));

/**
 * Returns array of values of key 'name' from array of objects
 * getNames([{'name': 'a', 'other': '-'}, {'name': 'b', 'other': '-'}]) -> ['a', 'b']
 */
export const getNames = R.pluck('name');

export const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty])
