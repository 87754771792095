import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { Portal } from 'react-portal';
import styled from 'styled-components';
import Body from './body';
import Footer from './footer';
import Header from './header';
import QuestionnaireFallback from './QuestionnaireFallback';
import './questionnaire.scss';
import { Helmet } from 'react-helmet';
import { gettext as _, valuesFromFieldSets} from 'core/utils';
import { useGetQuestionnaireQuery } from 'core/services/lms';

const Container = styled.div`
  background: #fff;
  width: 100%;
  padding: 45px 40px 40px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const Questionnaire = ({ node }) => {
  const { data: fieldSets = {}, isLoading } = useGetQuestionnaireQuery();

  if (isLoading) {
    return null;
  }

  return (
    <Portal node={node}>
      <Container>
        <Helmet>
          <title>
            {_('Questionnaire')} | {window.platformSettings.platform.platformName}
          </title>
        </Helmet>
        <Header />
        <Body fieldSets={fieldSets} />
        <Footer fieldValues={Object.values(valuesFromFieldSets(fieldSets))} />
      </Container>
    </Portal>
  );
};

Questionnaire.propTypes = {
  node: PropTypes.object.isRequired,
};

export default withErrorBoundary(Questionnaire, { FallbackComponent: QuestionnaireFallback });
