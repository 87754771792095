import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

export const lmsApi = createApi({
  reducerPath: 'lmsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/rg/api',
    prepareHeaders: (headers, { getState }) => {
      const csrftoken = Cookies.get('csrftoken');
      if (csrftoken) {
        headers.set('X-CSRFToken', csrftoken);
      }
      return headers;
    },
  }),

  tagTypes: [
    'Account',
    'AccountCompleteness',
    'Questionnaire',
    'QuestionnaireCompleteness',
    'Support',
  ],
  endpoints: (builder) => ({
    // Account Addon endpoints:
    getAccount: builder.query({
      query: () => ({ url: `/account-extras/fields/` }),
      providesTags: ['Account'],
      async onQueryStarted(
        arg,
        { dispatch, getState, extra, requestId, queryFulfilled, getCacheEntry, updateCachedData },
      ) {
        dispatch(lmsApi.util.prefetch('getAccountCompletenessState', undefined, { force: true }));
      },
    }),
    updateAccount: builder.mutation({
      query: (data) => {
        return {
          url: '/account-extras/update_field/',
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['Account', 'Questionnaire'],
    }),
    getAccountCompletenessState: builder.query({
      query: () => '/account-extras/account-completeness/',
      providesTags: ['AccountCompleteness'],
    }),

    // Questionnaire endpoints:
    getQuestionnaire: builder.query({
      query: () => '/questionnaire/fields/',
      providesTags: ['Questionnaire'],
    }),
    updateQuestionnaire: builder.mutation({
      query: (data) => {
        return {
          url: '/questionnaire/update-field/',
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['Account', 'AccountCompleteness', 'Questionnaire'],
    }),
    getQuestionnaireCompletenessState: builder.query({
      query: () => '/questionnaire/questionnaire-completeness/',
      providesTags: ['QuestionnaireCompleteness'],
    }),

    // Report endpoints
    globalReport: builder.mutation({
      query: (data) => {
        return {
          url: '/reports/global-report/',
          method: 'POST',
          body: data,
        };
      },
    }),

    profileInfoReport: builder.mutation({
      query: (data) => {
        return {
          url: '/reports/profile-info-report/',
          method: 'POST',
          body: data,
        };
      },
    }),

    discussionsReport: builder.mutation({
      query: (data) => {
        return {
          url: '/reports/discussions-report/',
          method: 'POST',
          body: data,
        };
      },
    }),

    // Support endpoints
    sendSupportEmail: builder.mutation({
      query: (data) => {
        return {
          url: '/support/send-request/',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Support'],
    }),
  }),
});

export const QuestionnaireCompleteness = {
  completed: 'completed',
  partial: 'partial',
  uncompleted: 'uncompleted',
};

export const {
  useGetAccountQuery,
  useGetAccountCompletenessStateQuery,
  useUpdateAccountMutation,
  useGetQuestionnaireQuery,
  useUpdateQuestionnaireMutation,
  useGetQuestionnaireCompletenessStateQuery,
  useGlobalReportMutation,
  useProfileInfoReportMutation,
  useDiscussionsReportMutation,
  useSendSupportEmailMutation,
} = lmsApi;
