import { useUpdateQuestionnaireMutation } from 'core/services/lms';
import { fieldsFromFieldSets, getNames, gettext as _, valuesFromFieldSets } from 'core/utils';
import * as R from 'ramda';
import QuestionnaireForm from './QuestionnaireForm';
import { asyncValidate } from './validators';
import QuestionnaireTabs from './QuestionnaireTabs';
import PropTypes from 'prop-types';

const Body = ({fieldSets}) => {
  const [updateField, result] = useUpdateQuestionnaireMutation();
  const fields = fieldsFromFieldSets(fieldSets);

  if (R.isEmpty(fieldSets)) {
    return <div>{_('There are no questions.')}</div>;
  }

  return (
    <QuestionnaireForm
      form="questionnaire"
      initialValues={valuesFromFieldSets(fieldSets)}
      destroyOnUnmount={false}
      enableReinitialize={true}
      keepDirtyOnReinitialize={true}
      touchOnChange={true}
      asyncChangeFields={getNames(R.filter((f) => f.widget !== 'text', fields))}
      asyncBlurFields={getNames(R.filter((f) => f.widget === 'text', fields))}
      asyncValidate={(...props) => asyncValidate(updateField, result, ...props)}
    >
      <QuestionnaireTabs />
    </QuestionnaireForm>
  );
};

Body.propTypes = {
  fieldSets: PropTypes.object.isRequired,
}

export default Body;
