import { useSendSupportEmailMutation } from 'core/services/lms';
import SupportForm from 'support/SupportForm';
import { gettext as _ } from 'core/utils';
import SuccessModal from 'support/SuccessModal';
import { useDispatch } from 'react-redux';
import { openModal } from 'core/modals/modals.slice';
import { reset } from 'redux-form';
import { useEffect } from 'react';
import SupportFormFeedback from 'support/SupportFormFeedback';

const AuthenticatedSupport = () => {
  const dispatch = useDispatch();
  const [sendEmail, result] = useSendSupportEmailMutation();

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(reset('support'));
      dispatch(
        openModal({
          content: SuccessModal,
          context: {},
          onCancel: () => null,
        }),
      );
    }
  }, [dispatch, result]);

  const handleSubmit = (values) => {
    sendEmail(values);
  };

  return (
    <>
      <h1>{_('Contact us')}</h1>
      <SupportForm onSubmit={handleSubmit} />
      {result.isError && (
        <SupportFormFeedback
          heading={_('Something went wrong.')}
          details={result.error.error || JSON.stringify(result.error.data)}
        />
      )}
    </>
  );
};

AuthenticatedSupport.propTypes = {};

export default AuthenticatedSupport;

