import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './AccountFieldLabel.module.scss';

const AccountFieldLabel = ({ label, fieldFor, required = false }) => (
  <label
    className={classnames('u-field-title', 'field-label', styles.label, {
      [styles.required]: required,
    })}
    htmlFor={fieldFor}
  >
    <span>{label}</span>
  </label>
);

AccountFieldLabel.propTypes = {
  label: PropTypes.string,
  fieldFor: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default AccountFieldLabel;
