import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gettext as _ } from 'core/utils';

const StyledSection = styled.div`
  border-top: 4px solid #f5f5f5;
`;

const Section = ({ heading = _('Section Heading'), description = '', children }) => {
  return (
    <StyledSection className="section">
      <h3 className="section-header">{heading}</h3>
      {description && <p className="account-settings-header-subtitle">{description}</p>}

      <div className="account-settings-section-body aboutTabSections-section-body">
        {children}
        {/* <div class="ui-loading-error is-hidden">
          <span class="fa fa-exclamation-triangle message-error" aria-hidden="true"></span>
          <span class="copy">An error occurred. Please reload the page.</span>
        </div> */}
      </div>
    </StyledSection>
  );
};

Section.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

export default Section;
