import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  content: () => null,

  id: 'cusomizations-modal',
  role: 'dialog',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal(state) {
      return initialState;
    },
    openModal(state, action) {
      return {
        ...initialState,
        ...action.payload,
        isOpen: true,
      };
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
