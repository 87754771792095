import NotificationAlert from 'components/NotificationAlert';
import { ROUTES } from 'core/routing';
import { gettext as _ } from 'core/utils';
import React from 'react';

const QuestionnaireCompletedNotification = (props) => {
  return (
    <NotificationAlert
      heading={_('Questionnaire')}
      message={_(
        'Thank you for sharing with us information about your work. You can update this information anytime. Enjoy our courses!',
      )}
      icon="octagon"
      action={{
        label: _('Update information'),
        href: ROUTES.questionnairePage,
      }}
    />
  );
};

QuestionnaireCompletedNotification.propTypes = {};

export default QuestionnaireCompletedNotification;
