import { gettext as _ } from 'core/utils';
import * as R from 'ramda';

// Sync:
export const required = (value) => (!!value ? undefined : _('Required'));

export const positiveNumber = (value) =>
  value >>> 0 === parseFloat(value) ? undefined : _('Cannot be a negative number');

const runSyncValidation = (fieldName, fieldValue) => {
  switch (fieldName) {
    case 'current_students':
      return required(fieldValue) || positiveNumber(fieldValue);
    default:
      return undefined;
  }
};

// Async:
// TRICKERY NOTE: async validation is used for fields auto-update
export const asyncValidate = (updateField, result, values, dispatch, props, fieldName) => {
  // here is the issue: async validation happens before any sync validations are processed (redux-form bug)
  // so, we have nothing to do - perform field validation here to prevent field update with invalid value...

  return new Promise((resolve, reject) => {
    // perform validation:
    const fieldError = runSyncValidation(fieldName, values[fieldName]);

    // don't update field in case of error:
    if (fieldError) {
      return reject({ [fieldName]: fieldError });
    }
    return updateField(R.pick([fieldName], values));
  });
};
