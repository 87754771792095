import NotificationAlert from 'components/NotificationAlert';
import { ROUTES } from 'core/routing';
import { gettext as _ } from 'core/utils';
import React from 'react';

const QuestionnairePartialNotification = (props) => {
  return (
    <NotificationAlert
      heading={_('Complete your questionnaire')}
      message={_(
        'Please share with us the general information that we need for statistics by filling out a simple questionnaire. After that, hundreds of different courses on our platform will become available to you.',
      )}
      icon="octagon"
      action={{
        label: _('Continue sharing'),
        href: ROUTES.questionnairePage,
      }}
    />
  );
};

QuestionnairePartialNotification.propTypes = {};

export default QuestionnairePartialNotification;
