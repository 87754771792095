import { closeModal } from 'core/modals/modals.slice';
import { useUpdateAccountMutation } from 'core/services/lms';
import { gettext as _ } from 'core/utils';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Buttons from '../../../components/Buttons';
import { ReactComponent as Banner } from './change-warning.svg';
import styles from './warningModal.module.scss';

const WarningModal = ({
  alt = _('Warning'),
  header,
  description,
  secondaryButtonText = _('Cancel'),
  primaryButtonText = _('Change'),
  banner = <Banner alt={alt} />,
}) => {
  const dispatch = useDispatch();
  const { onOk, onCancel, context } = useSelector((store) => store.modal);
  const [updateField] = useUpdateAccountMutation();

  const handleOk = () => {
    onOk && onOk();
    updateField(context);
    dispatch(closeModal());
  };

  const handleCancel = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <div>
      <div className={styles.bannerWrapper}>{banner}</div>
      {header && <h3 className={styles['modal-title']}>{header}</h3>}
      {description && <p className={styles['modal-text']}>{description}</p>}
      <div className={styles['modal-footer']}>
        <Buttons
          primaryLabel={primaryButtonText}
          primaryHandler={handleOk}
          secondaryLabel={secondaryButtonText}
          secondaryHandler={handleCancel}
        />
      </div>
    </div>
  );
};

WarningModal.propTypes = {
  alt: PropTypes.string,
  header: PropTypes.string,
  description: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  primaryButtonText: PropTypes.string,
  banner: PropTypes.node,
};

export default WarningModal;
