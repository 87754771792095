import React from 'react';
import PropTypes from 'prop-types';
import s from './NotificationAlert.module.scss';

const NotificationAlert = ({ heading, message, icon, action = {} }) => {
  return (
    <div className="account-notification">
      <div className="account-notification-inner">
        {icon && (
          <svg className={`alert-${icon}`}>
            <use xlinkHref={`#alert-${icon}`} />
          </svg>
        )}
        <h2>{heading}</h2>
        {message && <p dangerouslySetInnerHTML={{ __html: message }} />}
      </div>

      {(action.href || action.handler) && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href={action.href || ''} className={s['notification-btn']} onClick={action.handler}>
          <svg className={s['icon-user']}>
            <use xlinkHref="#icon-user" />
          </svg>
          {action.label}
        </a>
      )}
    </div>
  );
};

NotificationAlert.propTypes = {
  heading: PropTypes.string.isRequired,
  message: PropTypes.string,
  icon: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string,
    handler: PropTypes.func,
    href: PropTypes.string,
  }),
};

export default NotificationAlert;
