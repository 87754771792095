import { gettext as _ } from 'core/utils';
import React from 'react';
import WarningModal from 'core/modals/WarningModal';

const RegionChangeWarning = (props) => {
  return (
    <WarningModal
      alt={_('Region warning')}
      description={_(
        'Changing the region will affect your account information. You will need to update information related to your region.',
      )}
      header={_('Are you sure you want to change region?')}
    />
  );
};

RegionChangeWarning.propTypes = {};

export default RegionChangeWarning;
