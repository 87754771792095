import PropTypes from 'prop-types';
import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { Portal } from 'react-portal';
import AccountIncompleteNotification from './AccountIncompleteNotification';
import NotificationsFallback from './NotificationFallBack';
import QuestionnaireIncompleteNotification from './QuestionnaireIncompleteNotification';
import {
  useGetAccountCompletenessStateQuery,
  useGetQuestionnaireCompletenessStateQuery,
  QuestionnaireCompleteness,
} from 'core/services/lms';
import AccountActivationNotification from './AccountActivationNotification';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'core/routing';

const Notifications = ({ node }) => {
  const { data: accountCompleteness, isLoading: isAccountCompletenessLoading } =
    useGetAccountCompletenessStateQuery();

  const { data: questionnaireCompleteness = {}, isLoading: isQuestionnaireCompletenessLoading } =
    useGetQuestionnaireCompletenessStateQuery();

  const location = useLocation();

  if (isAccountCompletenessLoading || isQuestionnaireCompletenessLoading) {
    return null;
  }

  let notification = null;

  if (!window.platformSettings.user.isActive) {
    notification = <AccountActivationNotification />;
  } else if (!accountCompleteness?.completed) {
    notification = <AccountIncompleteNotification />;
  } else if (
    !(questionnaireCompleteness.status === QuestionnaireCompleteness.completed) &&
    location.pathname !== `${ROUTES.questionnairePage}/`
  ) {
    notification = <QuestionnaireIncompleteNotification />;
  }

  return (
    <Portal node={node}>
      <div className="rg-notifications">{notification}</div>
    </Portal>
  );
};

Notifications.propTypes = {
  node: PropTypes.object.isRequired,
};

export default withErrorBoundary(Notifications, { FallbackComponent: NotificationsFallback });
